
import { Image } from '@react-three/drei'
import * as THREE from "three";

export const BackgroundMap = (props: {floorPlane: THREE.Plane, url: string}) => {
    return ( 
        <Image 
            scale={1}
            rotation={[-Math.PI / 2, 0, 0]}
            position={[0, -0.1, 0]}
            toneMapped={false}  
            url={props.url} >
            <planeGeometry args={[30, 30]} />
        </Image>
    )
}