import { useParams } from "react-router-dom";
import { useLanguage } from "../../common/language/Language";
import { ActionItem, AppMenu } from "./components/AppMenu";
import { Image as ContentImage } from "../../common/content/api/Content";
import {
  duotone,
  light,
  solid,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  ActionIcon,
  Alert,
  BackgroundImage,
  Image,
  Box,
  Button,
  Group,
  Paper,
  Stack,
  Text,
  Title,
  Portal,
  Drawer,
  Card,
  Input,
  LoadingOverlay,
  Center,
  ScrollArea,
  Loader,
  Modal,
  Divider,
  SimpleGrid,
  em,
  Radio,
  CardSection,
  NumberInput,
  Grid,
} from "@mantine/core";
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useDisclosure,
  useLocalStorage,
  useSessionStorage,
  useViewportSize,
  useWindowScroll,
  useMediaQuery,
  useForceUpdate,
} from "@mantine/hooks";
import {
  KeyPoint,
  KeypointGroup,
  Layer,
  ParticipantInfo,
  Question,
  TrainingItem
} from "./api/TrainingApi";
import { Exagard_Colors, GlobalAppsettings } from "../../GlobalSettings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { renderMarkdown } from "../../common/content/RenderMarkdown";
import { ContentItem } from "../../common/content/api/Content";
import { useContent } from "../../common/content/useContent";
import { useSubscribe } from "../../common/pubsub/usePubsub";
import { QRScanner } from "../app/QRScanner";

// Lottie
import Lottie from "lottie-react";
import stars from "./animations/stars.json";

import { ResponseBase, ResponseData, ResultBase } from "../../common/api/common";
import { Http, fetcher } from "../../common/Fetcher";

import { UserFormInput } from "./components/UserInput";
import useSWR from "swr";
import { useFingerPrint } from "../../common/fingerprint/useFingerPrint";

import App from 'pubsub-js';
import { useMap } from "../maps/useMap";
import { Cards } from "../cards/Cards";
import { RouteLayer } from "../maps/api/RouteLayer";


export const TrainingModule = (props: {editMode: boolean, setAllowEdit: (value: boolean) => void}) => {
  const lang = useLanguage();

  const { id } = useParams();

  const { group } = useParams();

  const [error, setError] = useState<string>("");

  const { getContentItem } = useContent();


  const [showKeypointList, setShowKeypointList] = useState<boolean>(false);

  const [startedRound, setStartedRound] = useState<boolean>(false);

  const [currentQRCode, setCurrentQRCode] = useState<string | undefined>(
    undefined
  );

  const {visitorId} = useFingerPrint();

  const isMobile = useMediaQuery(`(max-width: ${em(750)})`);

  const [start, showStart] = useState<boolean>(false);
  const [exam, setExamVisible] = useState<boolean>(false);
  const [currentExamIndex, setCurrentExamIndex] = useState<number>(0);
  const [firstKeypoint, setFirstKeypoint] = useState<boolean>(true);
  const [gotoNextQuestion, setGotoNextQuestion] = useState<boolean>(false);

  const [flashAction, setFlashAction] = useState<boolean>(false);

  const [achivements, showAchivements] = useState<boolean>(false);
  const achivementContent = useContent({
    contentID: "6ySZR9Mz3zRrjKMx3H5xSx",
    locale: lang.locale(),
    preview: false,
  });

  const showExam = (value: boolean) => {
    if (!props.editMode) setExamVisible(value);
  }

  const [roundParticipants, setRoundParticipants] = useState<string | number>(
    1
  );
  const [zoomKeypoint, showZoomKeypoint] = useState<boolean>(false);

  const {data, isLoading, mutate: refresh} = useSWR<ResultBase<TrainingItem>>(
    () => '/api/training/GetTraining?id=' + id,
    fetcher,
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
      keepPreviousData: true,
      onError: (error: any) => {
        console.error(error);
      }
    }
  );

  useEffect(() => {
    if (data?.success) {
      setCurrent(data.result);
      if (props.setAllowEdit !== undefined && data.result.config.allowEdit !== undefined) {
        props.setAllowEdit(data.result.config.allowEdit);
      }
    }
  }, [data]);

  

  const [wrongAnswer, setWrongAnswer] = useState<boolean>(false);
  const [
    wrongAnswerDialog,
    { open: showWrongAnswerDialog, close: closeWrongAnswerDialog },
  ] = useDisclosure(false);

  const [current, setCurrent] = useState<TrainingItem>();
  const [currentLayer, setCurrentLayer] = useState<Layer | undefined>();

  useEffect(() => {
    if (current !== undefined) {
      filterLayerKeypoints();
    }
  }, [current]);

  const [currentkeypoint, setCurrentKeypoint] = useState<KeyPoint | undefined>(
    undefined
  );

  const [participant, setParticipant] = useSessionStorage<
    ParticipantInfo | undefined
  >({ key: GlobalAppsettings.AppId + "-participant" });

  const [startContent, setStartContent] = useSessionStorage<
    ContentItem | undefined
  >({ key: GlobalAppsettings.AppId + "-startContent" });

  const [actions, setActions] = useState<ActionItem[]>([
    {
      title: "",
      action: "/rounds",
      icon: duotone("arrow-left"),
    },
  ]);

  const userOrgConfig = {
    //if value from config is undefined, set default value to false
    collectUser: current?.config.collectUser ?? false,
    collectUserList: current?.config.collectUserList ?? false,
    collctFingerPrint: current?.config.collectFingerPrint ?? false,
    collectOrgInfo: current?.config.collectorgInfo ?? false,
  };

  const [currentQuestion, setCurrentQuestion] = useState<Question>();
  const currentQuestionindex = useRef<number>(0);

  const onMapLoaded = () => {
    setStartGroup();
  };

  const setStartGroup = () => {
    const round = current;
    if (round !== undefined) {
      if (round.groups !== undefined && round.groups.length > 0) {
        if (group !== undefined) {
          const groupExists = round.groups.some((g) => g.id === group);
          if (groupExists) {
            console.info("Set group ->", group);
            setCurrentGroup(group);
          } else {
            console.info("group not found ->", group);
            setCurrentGroup(round.groups[0].id);
          }
        } else {
          setCurrentGroup(round.groups[0].id);
        }
      }
    }
  }

  const saveKeyPoint = (keyPoint: KeyPoint, x: string, y: string, markerX: string, markerY: string) => {
    // Update keypoint coordinates
    const updatedKeyPoint = {
      ...keyPoint,
      coordinateX: x,
      coordinateY: y,
      markerX: markerX,
      markerY: markerY
    };

    console.log("updatedKeyPoint ->", updatedKeyPoint);

    fetcher<ResponseData<KeyPoint>>(
      GlobalAppsettings.BaseUrl +
          "/api/training/updateKeypoint?id=" + current?.id,
      Http.POST,
      undefined,
      updatedKeyPoint
    )
      .then((data: ResponseData<KeyPoint>) => {
        if (data !== undefined) {
          if (data.success === true) {
            refresh();
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });

    }

  const saveRouteLayers = (routeLayers: RouteLayer[]) => {
    console.log("saveRouteLayers ->", routeLayers);

    let round = current;
    if (round !== undefined) {
      
        fetcher<ResultBase<string>>(
          GlobalAppsettings.BaseUrl +
              "/api/training/updateRoutes?id=" + current?.id,
          Http.POST,
          undefined,
          routeLayers
        ).then((data: ResultBase<string>) => {
          if (data.success) {
            console.log("routes saved ->", data);
            //refresh();
          }
          else {
            console.error(data.errorMessage);
          }
        });
    }
  }

  const { renderMap,showdirections, setMapKeyPoints, renderTools, setMapCurrentKeypoint } =
    useMap({layer: currentLayer, keyPoints: current?.keyPoints, 
    mapLoaded: onMapLoaded,
    editMode: props.editMode,
    saveKeyPoint: saveKeyPoint,
    saveRouteLayers: saveRouteLayers,
    routes: current?.routes,
    isMobile: isMobile ?? false
  });


  const filterGroupKeypoints = () => {
    let keypoints: KeyPoint[] = [];

    if (current !== undefined) {
      current.keyPoints.forEach((keypoint) => {
        if (keypoint.group === currentGroup) {
          keypoints.push(keypoint);
        }
      });
    }

    setMapKeyPoints(keypoints);
    return keypoints;
  }

  const setKeypointNavigation = () => {
    let keypoint = currentkeypoint;
    let round = current;

    setActions([
      {
        title: "",
        action: "",
        icon: duotone("arrow-left"),
        execute: () => {
          //set prev keypoint
          let index = keypoint?.order;
          if (index !== undefined && index > -1) {
            setCurrentKeypoint(round?.keyPoints[index - 1]);
          }
        },
      },
      {
        title: lang.Text("Next"),
        action: "",
        icon: duotone("arrow-right"),
        execute: () => {
          let currentKeypoints = filterGroupKeypoints(); 

          console.log("currentKeypoints ->", currentKeypoints);

          const allKeypointsDone = currentKeypoints.every(keypoint => keypoint.isDone === true);
          if (allKeypointsDone  ) {
            console.log("all keypoints done in this group");
            //Todo: goto next group
            const index = current?.groups.findIndex(group => group.id === currentGroup);

            console.log("this group index ->", index);

            if (index !== undefined && index < (current?.groups.length ?? 0) - 1) {
              
              let nextGroup = current?.groups[index + 1];
              if (nextGroup !== undefined) {
                setCurrentGroup(nextGroup.id);
                setShowKeypointList(false);
              }    
            }
          } else {
            console.log("not all keypoints done in this layer");
            nextKeypoint();
          }
          
        },
      },
    ]);
  };

  const nextKeypoint = () => {
    let index = currentkeypoint?.order;
    //set next keypoint
    if (currentkeypoint != undefined) {
      index = currentkeypoint.order + 1;
    }
    if (
      index !== undefined &&
      current !== undefined &&
      index < current?.keyPoints.length
    ) {
      let nextKeypoint = current?.keyPoints.find(
        (kp) => kp.group === currentGroup && kp.order === index
      );

      setCurrentKeypoint(nextKeypoint);      
      setShowKeypointList(false);
    }
    else {
      //all done
      console.log("all done");
    }
  }

  useEffect(() => {
    if (currentkeypoint !== undefined) {
      setMapCurrentKeypoint(currentkeypoint);
    }
  }, [currentkeypoint]);

  const findLastDoneItemMapRef = (keyPoints?: KeyPoint[]) => {
    if (keyPoints == undefined) return "";

    let last: KeyPoint | undefined;

    keyPoints.forEach((kp) => {
      if (kp.group === currentGroup && kp.isDone) {
        last = kp;
        console.log("Last done keypoint ->", last);
      }
    });

    if (last !== undefined) return last.mapRef;
    else return "";
  };

  const closeExam = () => {
    let keypoint = currentkeypoint;
    let round = current;

    //update keypoint
    let keyPoints = current?.keyPoints;
    if (keyPoints !== undefined) {
      keyPoints.forEach((item) => {
        if (item.id === keypoint?.id) {
          item.isDone = true;
        }
      });
    }
    console.info("Update keypoint ->", keypoint);

    if (keyPoints !== undefined && round !== undefined) {
      round.keyPoints = keyPoints;
      console.info("Update round ->", round);

      setCurrent(round);
    }

   
    setKeypointNavigation();
    setShowKeypointList(true);
    showExam(false);
    

  };

  const filterLayerKeypoints = () => {
    let keypoints: KeyPoint[] = [];
    // let currentlayer = currentkeypoint?.layerRefs[0];
    if (current !== undefined) {
      current.keyPoints.forEach((keypoint) => {
        if (keypoint.layerRefs !== undefined) {
          keypoint.layerRefs.forEach((layer) => {
            if (layer === currentLayer?.id) {
              keypoints.push(keypoint);
            }
          });
        }
      });
    }
    setMapKeyPoints(keypoints);
    return keypoints;
  }

  useEffect(() => {
    filterLayerKeypoints();
  }, [currentLayer]);


  const reportFinishedRound = () => {
    
    let params = {
      id: id,
      orgId: current?.orgId,
      fingerprint: visitorId,
      data: [
        {
          key: 'roundId',
          value: current?.id
        },
        {
          key: 'roundName',
          value: current?.title
        },
        {
          key: 'roundDescription',
          value: current?.description
        },
      ],
    };

    console.info('report event ->', params);

    if (user !== undefined && user.length > 0) {
      params.data.push({
        key: 'user',
        value: user
      });
    }

    if (userList !== undefined && userList.length > 0) {
      params.data.push({
        key: 'userList',
        value: userList.join(',')
      });
    }

    if (orgInfo !== undefined && orgInfo.length > 0) {
      params.data.push({
        key: 'orgInfo',
        value: orgInfo  
      });
    }


    fetcher('/api/event/ReportFinishedRound',
      Http.POST,undefined,params).then((result) => {
      console.info('report finished round ->', result);
    });

  }

  useEffect(() => {
    if (current !== undefined) {
    
      //check if all keypoint are done
      let allDone = true;
      current.keyPoints?.forEach((item) => {
        if (!item.isDone) {
          allDone = false;
        }
      });

      if (allDone) {
        //show achivements
        showAchivements(true);

        reportFinishedRound();

        setActions([
          {
            title: "",
            action: "/",
            icon: duotone("home"),
          },
          // {
          //     title: lang.Text('Next'),
          //     action: '/achivements',
          //     icon: duotone('arrow-right')
          // }
        ]);
      } else {
        //Goto next keypoint
        
      }
    }
  }, [exam]);

  useEffect(() => {
    if (
      currentQuestion !== undefined &&
      currentQuestion.answers !== undefined
    ) {
      if (gotoNextQuestion) {
        if (wrongAnswer) {
          showWrongAnswerDialog();
        } else {
          ///nextQuestion();

          let index = currentQuestionindex.current;
          if (currentkeypoint?.exams[currentExamIndex].questions !== undefined) {
            setCurrentQuestionindex(index + 1);
          }
        }
        setGotoNextQuestion(false);
      }
    }
  }, [gotoNextQuestion]);

  const setCurrentQuestionindex = (i: number) => {
    if (i === currentkeypoint?.exams[currentExamIndex].questions.length) {
      console.info("last question");
      //last question, all answered?
      let allAnswered = true;
      //   currentkeypoint?.exams[0].questions.forEach((question) => {
      //     if (question.answers !== undefined) {
      //       let questionAnswered = false;
      //       question.answers.forEach((answer) => {
      //         if (answer.selected) {
      //           questionAnswered = true;
      //         }
      //       });
      //       if (!questionAnswered) {
      //         allAnswered = false;
      //       }
      //     }
      //   });

      if (allAnswered) {
        closeExam();
      }
      return;
    }

    if (
      currentkeypoint?.exams[currentExamIndex].questions !== undefined &&
      i < currentkeypoint?.exams[currentExamIndex].questions.length &&
      i >= 0
    ) {
      currentQuestionindex.current = i;
      console.info(currentQuestionindex.current);
    }
    setCurrentQuestion(
      currentkeypoint?.exams[currentExamIndex].questions[currentQuestionindex.current]
    );
  };

  useEffect(() => {
    if (!id) {
      setError("Round not found");
    } else {
      //find round
      const round = current;
      if (!round) {
        setError("Round not found");
      } else {
        if (startedRound) {
          //
          filterLayerKeypoints();
        } else {
          setCurrent(round);
          setCurrentLayer(round.layers[0]);
          setCurrentQRCode(undefined);
          closeBindDialog();
          closeWrongDialog();
          setCurrentKeypoint(undefined);
          setError("");
          showExam(false);
          // showKeypointInfo(false);
          // setCurrentQuestionindex(0);
          setFlashAction(false);

          //setMapId(round.layers[0].mediaRef);

          getContentItem(round.contentRef, lang.locale(), false).then(
            (result) => {
              setStartContent(result);
              setActions([
                {
                  title: "",
                  action: "/training",
                  icon: duotone("arrow-left"),
                },
                {
                  title: lang.Text("Start Round"),
                  action: "",
                  icon: duotone("arrow-right"),
                  execute: () => {
                    showStart(false);
                    setActions([
                      {
                        title: "",
                        action: "/training",
                        icon: duotone("arrow-left"),
                      },
                      {
                        title: "",
                        action: "",
                        icon: duotone("arrow-right"),
                        execute: () => {
                          setCurrentKeypoint(round.keyPoints[0]);
                          // setShowKeypointList(false);
                        },
                      },
                    ]);
                  },
                },
              ]);
              showStart(true);
              setStartedRound(true);
            }
          );
        }
      }
    }
  }, [current, id]);

  const [keypointContent, setKeypointContent] = useState<
    ContentItem | undefined
  >(undefined);
  const [keypointInfo, setKeypointInfo] = useState<boolean>(false);

  const startExam = () => {
    setCurrentQuestionindex(0);
    let exam = currentkeypoint?.exams[currentExamIndex];

    //find examindx with lang.locale()
    var examIndex = currentkeypoint?.exams.findIndex((exam) => exam.language === lang.locale());
    if (examIndex !== undefined) {
      exam = currentkeypoint?.exams[examIndex];
    }

    if (exam !== undefined) {
      setCurrentExamIndex(examIndex ?? 0);
      setCurrentQuestion(exam.questions[0]);

      //set order of answer options randomly
      exam.questions.forEach((question) => {
        question.answers.sort(() => Math.random() - 0.5);
      });
      setActions([
        {
          title: "",
          action: "",
          icon: duotone("arrow-left"),
          execute: () => {
            if (currentQuestionindex.current === 10) {
              showKeypointInfo(false);
              showExam(false);
            } else if (currentQuestionindex.current === 0) {
              showKeypointInfo(true);
              currentQuestionindex.current = 10;
            } else {
              prevQuestion();
            }
          },
        },
        {
          title: lang.Text("Next"),
          action: "",
          icon: duotone("arrow-right"),
          execute: () => {
            if (currentQuestionindex.current === 10) {
              currentQuestionindex.current = 0;
              showKeypointInfo(false);
            } else {
              setGotoNextQuestion(true);
            }
          },
        },
      ]);
      showExam(true);
    }
  };

  const showKeypointInfo = (value: boolean) => {
    if (!props.editMode) setKeypointInfo(value);
  }

  const prevQuestion = () => {
    let index = currentQuestionindex.current;
    if (currentkeypoint?.exams[currentExamIndex].questions !== undefined) {
      // if (index !== -1)
      // {
      setCurrentQuestionindex(index - 1);
      // }
    }
  };

  const selectAnswer = (answer: any) => {
    if (currentQuestion !== undefined) {
      let answers = currentQuestion.answers;

      answers.forEach((item) => {
        if (item.id === answer.id) item.selected = true;
        else item.selected = false;
      });

      setCurrentQuestion({ ...currentQuestion, answers: answers });
    }
  };

  const [keypointBindDialog, { open: openBindDialog, close: closeBindDialog }] =
    useDisclosure(false);
  const [
    keypointWrongDialog,
    { open: openWrongDialog, close: closeWrongDialog },
  ] = useDisclosure(false);


  const skipKeypoint = () => {
    setCurrentQRCode(undefined);
    showKeypointInfo(false);
    closeExam();
  }

  const atkeypoint = (data?: string) => {
    setFlashAction(false);

    let keypoint = currentkeypoint;
    if (keypoint === undefined) {
      console.info("keypoint not found");
    } else {

      //get content
      if (keypoint.contentRef !== undefined && keypoint.contentRef !== null && keypoint.contentRef.length > 0) {
        console.log("keypoint ->", keypoint.contentRef);
      
        getContentItem(keypoint.contentRef, lang.locale(), false).then(
          (result) => {
            setKeypointContent(result);
            setCurrentQRCode(undefined);

            console.log("keypoint content ->",lang.locale(),  result);

            setActions([
              {
                title: "",
                action: "",
                icon: duotone("arrow-left"),
                execute: () => {
                  showKeypointInfo(false);
                  //showStart(false);
                  //resetActions();
                },
              },
              {
                title: lang.Text("Next"),
                action: "",
                icon: duotone("arrow-right"),
                execute: () => {
                  showKeypointInfo(false);
                  if (
                    currentkeypoint?.exams !== undefined &&
                    currentkeypoint?.exams.length > 0
                  ) {
                    startExam();
                  } else {
                    closeExam();
                  }
                },
              },
            ]);
            showKeypointInfo(true);
          }
        );
      }
      else {
        skipKeypoint();

      }
    }
  };


 

  useEffect(() => {
    if (currentkeypoint !== undefined && current?.layers !== undefined && current?.layers.length > 0
       && currentkeypoint?.layerRefs?.[0] !== undefined
    ) {
      const layer = current?.layers.find(
        (layer) => layer.id === currentkeypoint?.layerRefs?.[0]
      );
      // console.log("currentlayer: ", layer);
      setCurrentLayer(layer);
    }

    if (currentkeypoint !== undefined) {

      showdirections(currentkeypoint.id, true);

      //console.log("currentkeypoint ->", currentkeypoint);

      switch (currentkeypoint.keypointType) {
        default:
        case "keypoint.qrcode":
          setActions([
            {
                title: lang.Text("Scan QR-code for ") + " " + lang.Text(currentkeypoint.title),
                action: "qr-code-scan",
                icon: duotone("qrcode"),
              execute: () => {
                setFlashAction(false);
                setFirstKeypoint(false);
              },
            },
          ]);
          if (firstKeypoint) {
            setFlashAction(true);
          }
          break;
        case "keypoint.card":
          //show card
          break;
        case "keypoint.start":
          nextKeypoint();
          break;
        case "keypoint.info":
          atkeypoint();
          break;
        
      }
    }
  }, [currentkeypoint]);

  useEffect(() => {
    if (currentQuestion !== undefined) {
      let isCorrect = false;
      currentQuestion?.answers.forEach((answer) => {
        if (answer.selected && answer.isCorrect) {
          isCorrect = true;
        }
      });
      if (isCorrect) {
        console.info("Correct answer");
        setWrongAnswer(false);
      } else {
        console.info("Wrong answer");
        setWrongAnswer(true);
      }
    }
  }, [currentQuestion]);


  useEffect(() => {
    if (current?.config.demoMode) {
      //no check
      atkeypoint();
    } else {
      //console.info("currentQRCode ->", currentQRCode);
      if (currentkeypoint !== undefined && currentQRCode !== undefined) {
        let keypoint = currentkeypoint;
        if (
          keypoint?.resourceRef !== null &&
          keypoint?.resourceRef !== undefined &&
          keypoint?.resourceRef.length > 0
        ) {
          //check if correct
          if (
            keypoint?.resourceRef.toLowerCase() === currentQRCode.toLowerCase()
          ) {
            //correct
            atkeypoint();
          } else {
            //wrong
            openWrongDialog();
          }
        } else {
          if (current?.config.allowBindKeypoints) {
            openBindDialog();
          }
        }
      }
    }
  }, [currentQRCode]);

  const bindKeypoint = () => {
    closeBindDialog();

    fetcher<ResponseData<KeyPoint>>(
      GlobalAppsettings.BaseUrl +
        "/api/training/bindKeypoint?id=" +
        id +
        "&orgId=" +
        current?.orgId +
        "&keypointId=" +
        currentkeypoint?.id +
        "&code=" +
        currentQRCode,
      Http.POST
    )
      .then((data: ResponseData<KeyPoint>) => {
        if (data !== undefined) {
          if (data.success === true) {
            
            window.alert(lang.Text('Nyckelpunkt kopplad.'));

            let round = current;
            let keypoint = currentkeypoint;
            let index = keypoint?.order;
            //set next keypoint
            if (keypoint != undefined) {
              index = keypoint.order + 1;
            }

            if (
              index !== undefined &&
              round !== undefined &&
              index < round?.keyPoints.length
            ) {
              let nextKeypoint = round.keyPoints.find(
                (kp) => kp.group === currentGroup && kp.order === index
              );

              setCurrentKeypoint(nextKeypoint);
              // setShowKeypointList(true);
            }
            setCurrentQRCode(undefined);
            showKeypointInfo(false);
            closeExam();
          } else {
            window.alert(data.errorMessage);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const renderKeyPointImages = (images: ContentImage[]) => {
    if (images.length > 0) {
      if (images[0].url.endsWith(".mp4")) {
        return (
          <div>
            <video
              controls={false}
              className="w-full"
              style={{ height: "86vh", objectFit: "cover" }}
              src={images[0].url}
              autoPlay
            />
          </div>
        );
      }
    }
  };

  
  const [user, setUser] = useState<string>('');
  const [userList, setUserList] = useState<string[]>([]);
  const [orgInfo, setOrgInfo] = useState<string>('');

  const handleUpdate = (user: string, userList: string[], orgInfo: string) => {
    setUser(user);
    setUserList(userList);
    setOrgInfo(orgInfo);
  }

  const [currentGroup, setCurrentGroup] = useState<string>("");

  const [currentGroupRef, setCurrentGroupRef] = useState<KeypointGroup | undefined>(undefined);

  useEffect(() => {
    if (currentGroup !== undefined) {
      var group = current?.groups.find((group) => group.id === currentGroup);
      if (group !== undefined && group.mapRef !== undefined) {
        var keypoints = current?.keyPoints.filter(
          (d) => d.group === currentGroup || d.group === "global"
        );

        if (keypoints !== undefined && keypoints.length > 0) {
          if (start === false) {
            setCurrentKeypoint(keypoints[0]);
          }
        }
        setCurrentGroupRef(group);
      }
    }
  }, [currentGroup, start]);


  const renderLayer = () => {
    if (currentLayer !== undefined) {
      switch (currentLayer.type) {
        case "keypoint.layer":
          //return renderImageMap();
          break;
        case "map.layer.3d":
        case "map.layer.2d":
          return renderMap();
      }
    }
  };

  const useLogo = false;

  return (
    <>
      {/* Logo + Group menu */}
      <Portal>
        <Stack className="fixed top-16" gap={6}>
          {current?.logoUrl && useLogo && (
            <div
              className="h-18 w-20 z-20 mt-2 p-2 border border-gray-300 rounded-r-md bg-white"
              onClick={() => showStart(true)}
            >
              <Image src={current?.logoUrl} fit="cover" />
            </div>
          )}
          {/* <div className='fixed top-16 left-24 z-50'>
              <p className='font-light text-md text-gray-400'>{current?.title}</p>
            </div> */}
          {current?.groups !== undefined &&
            current?.groups.length > 0 &&
            current?.groups.map((group, key) => {
              return (
                <div
                  key={key}
                  className={
                    "h-10 z-20 pt-2 border mt-1 border-gray-300 rounded-r-md transition-all duration-300 cursor-pointer " +
                    // + (key === 0 ? ' top-48' : key===1 ? 'top-60' : 'top-72') + ' '
                    (currentGroup === group.id
                      ? "bg-gray-900 text-white font-bold border-l-4 border-lime-500 px-5"
                      : "bg-white text-gray-500 font-light px-4")
                  }
                  onClick={() => setCurrentGroup(group.id)}
                >
                  <p className="text-md pointer-events-none">{group.title}</p>
                </div>
              );
            })}
        </Stack>
      </Portal>

      {/* Map + Keypoint content */}
      <div style={{width: '100vw', height: '100vh'}}>
      {renderLayer()}
      </div>

      {/* Keypoint menu */}
      <Portal>
        <Box
          hiddenFrom="sm"
          className=""
          style={{
            zIndex: 20,
            position: "fixed",
            top: "75px",
            //bottom: '95px',
            right: "10px",
          }}
        >
          <Button
            variant="transparent"
            size="md"
            radius="xl"
            color={"#1f2937"}
            className=" bg-gray-100 border border-gray-300"
            onClick={() => setShowKeypointList(true)}
          >
            <FontAwesomeIcon icon={light("list")} size="lg" />
          </Button>
          {/* <Button onClick={reportFinishedRound}>Test</Button> */}
        </Box>
      </Portal>

      {/* Keypoint list */}
      <Drawer
        opened={isMobile ? showKeypointList : true}
        onClose={() => setShowKeypointList(false)}
        position={isMobile ? "bottom" : "right"}
        padding={0}
        withOverlay={false}
        overlayProps={{ backgroundOpacity: 0, blur: 0 }}
        withCloseButton={isMobile ? true : false}
        closeButtonProps={{ size: "xl", mr: 2, mt: 2, color: "dark-gray" }}
        size={"55%"}
        transitionProps={{
          duration: 800,
          transition: isMobile ? "slide-up" : "slide-left",
        }}
        styles={{
          content: {
            backgroundColor: "white",
            color: Exagard_Colors._background,
            padding: 0,
            borderRadius: isMobile ? "1rem 1rem 0 0" : "none",
            border: isMobile
              ? "1px solid " + Exagard_Colors._medium_gray
              : "none",
            marginTop: isMobile ? 0 : "65px",
          },
          inner: isMobile
            ? { fontSize: 20, width: "100%" }
            : { fontSize: 20, display: "block", width: "30%", right: 0 },
        }}
        style={{
          zIndex: 15,
          position: "fixed",
        }}
        title={
          <div className="px-4 py-2 text-gray-500 font-thin text-sm ">
            {lang.Text(current?.title)} -{" "}
            {lang.Text(current?.groups.find((group) => group.id === currentGroup)?.title)}
          </div>
        }
      >
        <ScrollArea
          // h={250}
          className="mt-2"
          variant="hover"
        >
          <Stack gap={8} className="pt-2 mb-[250px] p-4">
            {current?.keyPoints
              .filter((d) => d.group === currentGroup || d.group === "global")
              .map((item, key) => {
                if (item.keypointType === "keypoint.start") item.isDone = true;
                if (item.id === currentkeypoint?.id)
                  return (
                    <Button
                      variant="filled"
                      radius={"md"}
                      color={Exagard_Colors._dark_green}
                      key={key}
                      onClick={() => {
                        // setShowKeypointList(false);
                        setCurrentKeypoint(item);
                      }}
                      justify="space-between"
                      rightSection={<></>}
                      // className='p-6 text-white'
                      // p={'xl'}
                      size="lg"
                    >
                      {" "}
                      {}
                      {item.order + 1}. {lang.Text(item.title)}
                    </Button>
                  );
                else
                  return (
                    <Button
                      variant="outline"
                      radius={"md"}
                      color={"#e5e7eb"}
                      size="lg"
                      key={key}
                      className={
                        item.isDone
                          ? "bg-lime-300 text-gray-700"
                          : "bg-white text-gray-700"
                      }
                      onClick={() => {
                        // setShowKeypointList(false);
                        setCurrentKeypoint(item);
                      }}
                      justify="space-between"
                      rightSection={
                        item.isDone ? (
                          <FontAwesomeIcon
                            icon={solid("check-circle")}
                            size="xl"
                            color={Exagard_Colors._dark_green}
                          />
                        ) : (
                          <></>
                        )
                      }
                    >
                      <div className="text-gray-800">
                        {item.order + 1}. {lang.Text(item.title)}
                      </div>
                    </Button>
                  );
              })}

            {renderTools()}
          </Stack>
        </ScrollArea>
      </Drawer>

      {/* Exam content*/}
      <Drawer
        opened={exam}
        onClose={() => showExam(false)}
        position="bottom"
        padding={0}
        overlayProps={
          isMobile
            ? { backgroundOpacity: 0.5, blur: 4 }
            : { backgroundOpacity: 0.5, blur: 4 }
        }
        withCloseButton={false}
        size={"95%"}
        transitionProps={{ duration: 1200, transition: "slide-up" }}
        styles={{
          content: {
            backgroundColor: "white",
            color: Exagard_Colors._background,
            padding: 0,
            borderRadius: isMobile ? "1rem 1rem 0 0" : "1rem",
            border: isMobile
              ? "1px solid " + Exagard_Colors._light_gray
              : "1px solid " + Exagard_Colors._light_gray,
            position: "fixed",
            top: isMobile ? "65px" : "65px",
            left: isMobile ? "0px" : "10vw",
            right: isMobile ? "0px" : "10vw",
            bottom: isMobile ? "0px" : "65px",
          },
          inner: isMobile
            ? { fontSize: 20 }
            : { display: "block", width: "50%", right: 0 },
        }}
        // style={{ zIndex: 200, position: 'fixed' }}
      >
        <Card>
          <Card.Section h={isMobile ? 120 : 150} className="bg-blacK">
            {keypointContent?.media && (
              <Image
                src={keypointContent?.media.url}
                alt={"round-logo"}
                className="min-h-[120px] max-h-[120px]"
                fit="contain"
              />
            )}
            {/* {current?.logoUrl &&
                <div className="absolute top-0">
                    <Image
                        src={current?.logoUrl}
                        fit="cover"
                    />
                </div>} */}
          </Card.Section>

          {keypointInfo ? (
            <>
              <Card.Section className="overflow-auto min-h-[250px] py-2 px-4 pb-20">
                <Title order={4} className="py-4">
                  {keypointContent?.caption}
                </Title>
                {renderMarkdown(keypointContent?.copy ?? "","key",isMobile ? "text-[18px]": "")}
              </Card.Section>
            </>
          ) : (
            <>
              <Card.Section className="min-h-[50px] py-2 px-6">
                <Text className="py-4 font-semibold">
                  {currentQuestion?.title}
                </Text>
              </Card.Section>
              <Card.Section className="min-h-[50px] py-2 px-6">
                <Text className="text-md font-light">
                  {currentQuestion?.text}
                </Text>
              </Card.Section>
              <Card.Section className="min-h-[50px] p-8 pb-56">
                <Stack>
                  {currentQuestion?.answers.map((answer, key) => {
                    return (
                      <Paper
                        radius={"md"}
                        withBorder
                        className="font-light p-4 text-[18px]"
                        onClick={() => {
                          selectAnswer(answer);
                        }}
                        //variant={answer.selected ? 'filled' : 'outline'}
                        key={key}
                        c={answer.selected ? "white" : Exagard_Colors._gray}
                        bg={
                          answer.selected ? Exagard_Colors._green : "whitesmoke"
                        }
                      >
                        {answer.text}
                      </Paper>
                    );
                  })}
                </Stack>
                {/* {wrongAnswer ?<>
                  <Alert color='red'>{lang.Text("Wrong answer")}</Alert>
                  </> : <></>} */}
              </Card.Section>
            </>
          )}
        </Card>
      </Drawer>

      {/* Wrong answer dialog */}
      <Modal
        opened={wrongAnswerDialog}
        onClose={() => {
          // setWrongAnswer(false);
          closeWrongAnswerDialog();
        }}
      >
        <Title order={4}>{lang.Text("Wrong answer")}</Title>
        <Text className="my-4">
          {lang.Text("Look thru the options and try again.")}
        </Text>
      </Modal>

      {/* Achivement content*/}
      <Drawer
        opened={achivements}
        onClose={() => showAchivements(false)}
        position="bottom"
        padding={0}
        overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
        withCloseButton={false}
        size={"95%"}
        transitionProps={{ duration: 1200, transition: "slide-up" }}
        styles={{
          content: {
            backgroundColor: "white",
            color: Exagard_Colors._background,
            padding: 0,
            borderRadius: "1rem 1rem 0 0",
            border: "1px solid " + Exagard_Colors._gray,
          },
          // inner: { fontSize: 20 },
        }}
        style={{ zIndex: 200, position: "fixed" }}
      >
        <Card>
          <Card.Section h={160} className="bg-blacK">
            {achivementContent?.contentItem?.media && (
              <Image
                src={achivementContent?.contentItem?.media.url}
                alt={"round-logo"}
                className="min-h-[160px] max-h-[160px]"
                fit="cover"
              />
            )}
            {/* {current?.logoUrl &&
                <div className="absolute top-0">
                    <Image
                        src={current?.logoUrl}
                        fit="cover"
                    />
                </div>} */}
          </Card.Section>
          <Card.Section className="min-h-[250px] py-2 px-4">
            <Title order={4} className="py-4">
              {achivementContent?.contentItem?.caption}
            </Title>
            {renderMarkdown(achivementContent?.contentItem?.copy ?? "")}
          </Card.Section>
          <Card.Section>
            <Lottie animationData={stars} />
          </Card.Section>
        </Card>
      </Drawer>

      {/* Keypoint content*/}
      <Drawer
        opened={keypointInfo}
        onClose={() => showKeypointInfo(false)}
        position="bottom"
        padding={0}
        overlayProps={
          isMobile
            ? { backgroundOpacity: 0.5, blur: 4 }
            : { backgroundOpacity: 0.5, blur: 4 }
        }
        withCloseButton={false}
        size={"95%"}
        transitionProps={{ duration: 1200, transition: "slide-up" }}
        styles={{
          content: {
            backgroundColor: "white",
            color: Exagard_Colors._background,
            padding: 0,
            borderRadius: isMobile ? "1rem 1rem 0 0" : "1rem",
            border: isMobile
              ? "1px solid " + Exagard_Colors._light_gray
              : "1px solid " + Exagard_Colors._light_gray,
            // marginTop: isMobile ? 0 : '65px',
            position: "fixed",
            top: isMobile ? "65px" : "65px",
            left: isMobile ? "0px" : "10vw",
            right: isMobile ? "0px" : "10vw",
            bottom: isMobile ? "0px" : "65px",
          },
          inner: isMobile
            ? { fontSize: 20 }
            : { display: "block", width: "50%", right: 0 },
        }}
        style={{ zIndex: 200, position: "fixed" }}
      >
        
        <Card>
          <Card.Section h={150} className="bg-blacK">
            {keypointContent?.media && (
              <>
                <Image
                  src={keypointContent?.media.url}
                  alt={"round-logo"}
                  className="min-h-[150px] max-h-[150px]"
                  fit="contain"
                  onClick={() => showZoomKeypoint(true)}
                />
                <div className="absolute top-0 right-0">
                  <Button
                    color="white"
                    variant="subtle"
                    onClick={() => showZoomKeypoint(true)}
                  >
                    {lang.Text("Press here to zoom")}
                  </Button>
                </div>
              </>
            )}
            {/* {current?.logoUrl && (
                  <div className='absolute top-0'>
                    <Image src={current?.logoUrl} fit='cover' />
                  </div>
                )} */}
          </Card.Section>
          <ScrollArea className="mx-2 " type="never">
            <Card.Section className="min-h-[250px] py-2 px-6 mb-20">
              {keypointContent?.images && keypointContent.images.length > 0 ? (
                <>{renderKeyPointImages(keypointContent?.images)}</>
              ) : (
                <>
                  <Title order={4} className="py-4">
                    {keypointContent?.caption}
                  </Title>
                  {renderMarkdown(keypointContent?.copy ?? "",keypointContent?.sys.id, isMobile ? "text-[18px]": "")}
                </>
              )}
            </Card.Section>
          </ScrollArea>
        </Card>
      </Drawer>
      
      {/* Zoom Image */}
      <Modal
        opened={zoomKeypoint}
        onClose={() => showZoomKeypoint(false)}
        title={currentkeypoint?.title}
        centered
          size="auto"
        //fullScreen={true}
      >
        {keypointContent?.media && (
          <Image
            src={keypointContent?.media.url}
              alt={"round-logo"}
              className="w-full h-full"
              fit="cover"
          />
        )}
      </Modal>

      {/* Start content*/}
      <Drawer
        opened={start}
        onClose={() => showStart(false)}
        position={isMobile ? "bottom" : "top"}
        padding={0}
        // overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
        withCloseButton={false}
        size={isMobile ? "95%" : "65%"}
        transitionProps={{ duration: 800, transition: "slide-up" }}
        styles={{
          root: { zIndex: 200 },
          content: {
            backgroundColor: "white",
            color: Exagard_Colors._background,
            padding: 0,
            //borderRadius: isMobile ? "1rem 1rem 0 0" : "1rem",
            //border: "1px solid " + Exagard_Colors._gray,
            position: "fixed",
            top: isMobile ? "64px" : "64px",
            left: isMobile ? "0px" : "20vw",
            right: isMobile ? "0px" : "20vw",
            bottom: 0,
          },
          // inner: { fontSize: 20 },
        }}
        style={{ zIndex: 200, position: "fixed" }}
      >
        <Card>
          <Card.Section h={160} className="bg-blacK">
            {current?.imageUrl && (
              <Image
                src={current?.imageUrl}
                alt={"round-logo"}
                className="min-h-[160px] max-h-[160px] "
                fit="cover"
              />
            )}
            {current?.logoUrl && (
              <div className="absolute top-0 p-1 border border-gray-300 rounded-r-xl bg-white">
                <Image src={current?.logoUrl} fit="cover" />
              </div>
            )}
          </Card.Section>
          <Card.Section className="min-h-[50px] py-2 px-4">
            <Title order={4}>{current?.title}</Title>
            <Title order={3} className="py-4">
              {startContent?.caption}
            </Title>
            {renderMarkdown(startContent?.copy ?? "")}
          </Card.Section>
          <UserFormInput userOrgConfig={userOrgConfig} onUpdate={handleUpdate} ></UserFormInput>
        </Card>
        
        {/* <Cards content={startContent} /> */}
      </Drawer>
      
      {/* Bind Keypoint dialog */}
      <Modal
        opened={keypointBindDialog}
        onClose={() => {
          closeBindDialog();
          setCurrentQRCode(undefined);
        }}
        title={lang.Text("Bind Keypoint?")}
        centered
      >
        <Text className="p-6">
          {lang.Text("Do you want to bind the Keypoint to this QR code?")}
        </Text>
        <Divider className="my-8" />
        <Group grow>
          <Button variant="filled" onClick={() => bindKeypoint()}>
            {lang.Text("Yes")}
          </Button>
          <Button
            variant="outline"
            onClick={() => {
              closeBindDialog();
              setCurrentQRCode(undefined);
              showKeypointInfo(false);
            }}
          >
            {lang.Text("No")}
          </Button>
        </Group>
      </Modal>

      {/*Wrong keypoint dialog*/}
      <Modal
        opened={keypointWrongDialog}
        onClose={() => {
          showKeypointInfo(false);
          closeWrongDialog();
        }}
        title={lang.Text("Wrong keypoint")}
        centered
      >
        <Text className="p-6">
          {lang.Text("Find the correct keypoint and scan that QR-code")}
        </Text>
      </Modal>

      <QRScanner
        demoMode={current?.config.demoMode}
        onScanned={(data: string) => {
          setCurrentQRCode(data);
          showKeypointInfo(true);
          //console.log(keypointInfo, "<--------");
        }}
        dialog={isMobile ? false : true}
      />


      <AppMenu actions={actions} flash={false}  ></AppMenu>
    </>
  );
};
