import { useRef } from "react";
import { useState } from "react";
import { KeyPoint } from "../../training/api/TrainingApi";
import { useDrag, useGesture } from "@use-gesture/react";
import { animated, useSpring } from "@react-spring/three";
import { Canvas, ThreeEvent, useThree, Vector3 } from "@react-three/fiber";
import { Box, CameraControls, Center, Loader, OrbitControls, OrthographicCamera, Sphere, useTexture, Text, Line, Circle  } from "@react-three/drei";

import * as THREE from "three";
import { Exagard_Colors } from "../../../GlobalSettings";
import { RayEvent } from "../api/Raycasting";


const evacuation_route = "https://images.ctfassets.net/eeuyi8y7y8ak/4sTBPYpH3xCbFsk9hXCiSX/b9cf68a9f49b73430cde34caaa3c1d8b/evacuation-route.png";
const defilibrator = "https://images.ctfassets.net/eeuyi8y7y8ak/7gROafVRj9j8AhAh1jXpPC/4637f63a5086903454fa03325b24e4ea/defilibrator.png";
const evacuation_plan = "https://images.ctfassets.net/eeuyi8y7y8ak/7AhCPwmjhg95CIvOf8eCKe/4e26c882985e3e8ed46830952eb395e0/evacuation-plan.png";
const evacuation_point = "https://images.ctfassets.net/eeuyi8y7y8ak/5snmkJXgRMC5jh0SKyME4L/1044ffc7c52488c448ee94fa196d7f35/evacuation-point.png";
const fire_ext = "https://images.ctfassets.net/eeuyi8y7y8ak/c4debe73-cc72-431a-9a9c-7215cdc48d97/3d369c772694f83451b1da74ef163ef7/c4debe73-cc72-431a-9a9c-7215cdc48d97";
const evacuation_bag = "https://images.ctfassets.net/eeuyi8y7y8ak/5VQCJBGQjj0KORF6OfamhJ/1a786a7069b0db8507688e459af7bdc9/evacuation-bag.png";
const medic = "https://images.ctfassets.net/eeuyi8y7y8ak/28oAOAPHj2XoqAUwjDWnfx/6aa4ec438af71007d9e50ad60cd96f07/medic.png";
const default_symbol = "https://images.ctfassets.net/eeuyi8y7y8ak/Ahd3e46pq4zSn0FdXXHPk/8e3fcad76de930e3954121c5481f2c2e/default.png";
const start = "https://images.ctfassets.net/eeuyi8y7y8ak/1N2XNLjnUv2qv9KEELj9EN/7450cd523de2ce2cb36fd92151df35ac/start.png";

const done = "https://images.ctfassets.net/eeuyi8y7y8ak/6x6PczQkwYB4G6dvwfYPqp/ec67b238ada44bc34df254b176899bb3/all-done-icon-13.jpg";


const getKeypointDoneTexture = (type:string) => {
    if (type === undefined || type === "") return done;
    if (type.includes("start")) return start;
    return done;
}

const getKeyPointTexture = (type: string) => {
    if (type === undefined || type === "") return default_symbol;
    if (type.startsWith("https")) return type;
    
    if (type.includes("evacuation.route")) return evacuation_route;
    if (type.includes("defilibrator")) return defilibrator;
    if (type.includes("evacuation.plan")) return evacuation_plan;
    if (type.includes("evacuation.point")) return evacuation_point;
    if (type.includes("evacuation.bag")) return evacuation_bag;
    if (type.includes("medic")) return medic;
    if (type.includes("fire.ex")) return fire_ext;
    if (type.includes("start")) return start;
    return default_symbol;
}

const getMarkerColor = (type: string) => {
    if (type === undefined || type === "") return Exagard_Colors._dark_green;

    if (type.includes("evacuation.route")) return Exagard_Colors._dark_green;
    if (type.includes("defilibrator")) return Exagard_Colors._dark_green;
    if (type.includes("evacuation.plan")) return Exagard_Colors._dark_green;
    if (type.includes("evacuation.point")) return Exagard_Colors._dark_green;
    if (type.includes("evacuation.bag")) return Exagard_Colors._dark_green;
    if (type.includes("medic")) return Exagard_Colors._dark_green;
    if (type.includes("fire.ex")) return "red";
    if (type.includes("start")) return "orange";
    return "gray";
}

const getMarkerType = (type: string) => {
    if (type.includes("evacuation.plan")) return "box";
    if (type.includes("fire.ex")) return "box";
    return "circle";
}


const getMarkerSize = (type: string) => {
    if (type.includes("start")) return 0.6;
    if (type.includes("evacuation.point")) return 0.6;
    return 0.3;
}


export const KeyPointMarker = (props: {keyPoint: KeyPoint, 
    setIsDragging: (value: boolean) => void,
    floorPlane: THREE.Plane,
    saveKeyPoint?: (keyPoint: KeyPoint, position: THREE.Vector3, markerPosition: THREE.Vector3) => void,
    editMode?: boolean,
    setMarkerPos?: (position: THREE.Vector3) => void
}) => {

const keypointHeight = 1.2;

const [pos, setPos] = useState([props.keyPoint.coordinateX, keypointHeight, props.keyPoint.coordinateY]);
const [textPos, setTextPos] = useState([props.keyPoint.textX ? props.keyPoint.textX : props.keyPoint.coordinateX, 
        0.1, props.keyPoint.textY ? props.keyPoint.textY : props.keyPoint.coordinateY]);
const [markerPos, setMarkerPos] = useState([props.keyPoint.markerX ? props.keyPoint.markerX : props.keyPoint.coordinateX, 
        0.1, props.keyPoint.markerY ? props.keyPoint.markerY : props.keyPoint.coordinateY]);
const [scale, setScale] = useState( props.keyPoint.isActive ? 1.8 : 1.5);

const dragObjectRef = useRef();
let planeIntersectPoint = new THREE.Vector3();

const bindPos = useGesture({
    onDrag: ({ active, event }) => {
         if (active) {                
            let _event = event as unknown as RayEvent;
            _event.ray.intersectPlane(props.floorPlane, planeIntersectPoint);
            setPos([planeIntersectPoint.x, keypointHeight, planeIntersectPoint.z]);
        }
    }
})

const bindTextPos = useGesture({
    onDrag: ({ movement: [x, y], active, event }) => {
         if (active) {                
            let _event = event as unknown as RayEvent;
            _event.ray.intersectPlane(props.floorPlane, planeIntersectPoint);
            setTextPos([planeIntersectPoint.x, 0.1, planeIntersectPoint.z]);
        }
    }
})

const bindMarkerPos = useGesture({
    onDrag: ({ movement: [x, y], active, event }) => {
         if (active) {                
            let _event = event as unknown as RayEvent;
            _event.ray.intersectPlane(props.floorPlane, planeIntersectPoint);
            setMarkerPos([planeIntersectPoint.x, 0.1, planeIntersectPoint.z]);
        }
    }
})


const movePosDown = (event: ThreeEvent<PointerEvent>) => {
    if (props.editMode) {
        props.setIsDragging(true);
        let c = bindPos();
        if (c && c.onPointerDown) c.onPointerDown(event as unknown as React.PointerEvent)
    }
  }

const movePosUp = (event: ThreeEvent<PointerEvent>    ) => {
    if (props.editMode) {
        props.setIsDragging(false);
    
        if (props.saveKeyPoint) {
            props.saveKeyPoint(props.keyPoint, new THREE.Vector3(Number(pos[0]), keypointHeight, Number(pos[2])), 
                new THREE.Vector3(Number(markerPos[0]), 0.1, Number(markerPos[2])));
        }
        let c = bindPos();
        if (c && c.onPointerUp) c.onPointerUp(event as unknown as React.PointerEvent);
    }
}

const movePos = (event: ThreeEvent<PointerEvent>) => {
    if (props.editMode) {
        let c = bindPos();
        if (c && c.onPointerMove) c.onPointerMove(event as unknown as React.PointerEvent)
    }
}

const moveMarkerDown = (event: ThreeEvent<PointerEvent>) => {
    if (props.editMode) {
        props.setIsDragging(true);
        let c = bindMarkerPos();
        if (c && c.onPointerDown) c.onPointerDown(event as unknown as React.PointerEvent)
    }
}

const moveMarker = (event: ThreeEvent<PointerEvent>) => {
    if (props.editMode) {
        let c = bindMarkerPos();
        if (c && c.onPointerMove) c.onPointerMove(event as unknown as React.PointerEvent)
    }
}

const moveMarkerUp = (event: ThreeEvent<PointerEvent>) => {
    if (props.editMode) {
        props.setIsDragging(false);
        if (props.saveKeyPoint) {
            props.saveKeyPoint(props.keyPoint, new THREE.Vector3(Number(pos[0]), keypointHeight, Number(pos[2])), 
                new THREE.Vector3(Number(markerPos[0]), 0.1, Number(markerPos[2])));
        }
        let c = bindMarkerPos();
        if (c && c.onPointerUp) c.onPointerUp(event as unknown as React.PointerEvent)
    }
}

const texture = useTexture({
    map: getKeyPointTexture(props.keyPoint.symbolRef)
});

const done_texture = useTexture({
    map: getKeypointDoneTexture(props.keyPoint.symbolRef)
});

const markerType = getMarkerType(props.keyPoint.symbolRef);





return (<>

    {props.keyPoint.isDone ? <>
    
    <Circle
        args={[getMarkerSize(props.keyPoint.symbolRef), 32]}
        scale={ props.keyPoint.isActive ? 1.8 : scale}
        rotation={[-Math.PI / 2, 0, 0]}
        position={markerPos as Vector3}        
    >
        <meshBasicMaterial {...done_texture} />
    </Circle>
    
    
    </> : <>
    
    {markerType === "box" ?
    <Box
        args={[1, 1, 0.05]}
        scale={ props.keyPoint.isActive ? 1.8 : scale}
        rotation={[-Math.PI / 2, 0, 0]}
        onPointerDown={(event) => movePosDown(event) }
        onPointerMove={(event) => movePos(event)}
        onPointerUp={(event) => movePosUp(event)}
        onPointerOver={(event) => setScale(1.8)}
        onPointerOut={(event) => setScale(1.5)}
        onClick={(event) => console.log(event)}
        position={pos as Vector3}
        ref={dragObjectRef.current}
    >
        <meshBasicMaterial {...texture} />
    </Box> : null} 
    
    {markerType === "circle" ?
    <Circle
        args={[getMarkerSize(props.keyPoint.symbolRef), 32]}
        scale={ props.keyPoint.isActive ? 1.8 : scale}
        rotation={[-Math.PI / 2, 0, 0]}
        onPointerDown={(event) => movePosDown(event) }
        onPointerMove={(event) => movePos(event)}
        onPointerUp={(event) => movePosUp(event)}
        onPointerOver={(event) => setScale(1.8)}
        onPointerOut={(event) => setScale(1.5)}
        onClick={(event) => console.log(event)}
        position={pos as Vector3}
        ref={dragObjectRef.current}
    >
        <meshBasicMaterial {...texture} />
    </Circle> : null} 


    <Sphere
        args={[0.20]}
        position={markerPos as Vector3}
        onPointerDown={(event) => moveMarkerDown(event)}
        onPointerMove={(event) => moveMarker(event)}
        onPointerUp={(event) => moveMarkerUp(event)}
        >   
        <meshBasicMaterial color={getMarkerColor(props.keyPoint.symbolRef)} />
    </Sphere>

    <Line 
        // scale={2}
        lineWidth={3}
        color={getMarkerColor(props.keyPoint.symbolRef)}
        points={[pos as Vector3, markerPos as Vector3]} >
        <lineBasicMaterial color={getMarkerColor(props.keyPoint.symbolRef)} />
    </Line>

    {/*
    <Text color={Exagard_Colors._dark_green} anchorX="center" anchorY="middle"
    //     position={
    //         getTextPos(pos)
    //     }
    //     rotation={[-Math.PI / 2, 0, 0]}
    //     scale={1.1}
    //     fontWeight={1000}
    //         >
    //     {props.keyPoint.title}
    // </Text>
    //  : null} 
    {/* <Image 
        scale={1}
        onPointerMove={() => console.log("move")}
        // color={"white"}
        rotation={[-Math.PI / 2, 0, 0]}
        position={[0, 0.8, 5]}
        toneMapped={false}
          
        url={"https://images.ctfassets.net/eeuyi8y7y8ak/01zoEPoWDEs8iTc2e3cKY3/f87423d3219789346f3cef446c88b0db/_tersam.gif"}
        //</>url={"https://images.ctfassets.net/eeuyi8y7y8ak/c4debe73-cc72-431a-9a9c-7215cdc48d97/3d369c772694f83451b1da74ef163ef7/c4debe73-cc72-431a-9a9c-7215cdc48d97?h=250"} 
        >
        <planeGeometry args={[1, 1]} />
    </Image>  */}
    </>}
</>)
}