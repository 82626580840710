import { CardSection, TagsInput, TextInput } from "@mantine/core";
import { useLanguage } from "../../../common/language/Language";
import { useEffect, useState } from "react";

export interface UserFormConfig {
  userOrgConfig: {
    collectUser: boolean;
    collectUserList: boolean;
    collctFingerPrint: boolean;
    collectOrgInfo: boolean;
  };
  onUpdate: (user: string, userList: string[], orgInfo: string) => void;
}

export const UserFormInput = (props: UserFormConfig) => {
  const lang = useLanguage();

  const [user, setUser] = useState<string>('');
  const [userList, setUserList] = useState<string[]>([]);
  const [orgInfo, setOrgInfo] = useState<string>('');

  const handleUpdate = () => {
    props.onUpdate(user, userList, orgInfo);
  }

  useEffect(() => {
    handleUpdate();
  }, [user, userList, orgInfo]);

  return (
    <>
      <CardSection className="px-10">
        {props.userOrgConfig.collectUser && (
          <TextInput
            label={lang.Text("Name")}
            description={lang.Text("")}
            placeholder={lang.Text("Enter your name")}
            radius="md"
            className="mb-5"
            fs={"16px"}
            size="lg"
            value={user}
            onChange={(e) => setUser(e.target.value)}
          />
        )}
        {props.userOrgConfig.collectUserList && (
          <TagsInput
            label={lang.Text("Participants")}
            placeholder={lang.Text("Press enter to submit each name")}
            clearable
            className="mb-5"
            radius="md"
            size="lg"
            fs={"16px"}
            value={userList}
            onChange={(e) => setUserList(e)}
          />
        )}
        {props.userOrgConfig.collectOrgInfo && (
          <TextInput
            label={lang.Text("Other information (e.g. department)")}
            description={lang.Text("")}
            placeholder={lang.Text("Enter info about organisation")}
            radius="md"
            size="lg"
            fs={"16px"}
            value={orgInfo}
            onChange={(e) => setOrgInfo(e.target.value)}
          />
        )}
      </CardSection>
    </>
  );
};
