import { Button, Card } from "@mantine/core"
import { useLanguage } from "../common/language/Language";
import { QRScanner } from "../modules/app/QRScanner";
import App from "pubsub-js"
import { Exagard_Colors, GlobalAppsettings } from "../GlobalSettings";
import { useMediaQuery, useOs } from "@mantine/hooks";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { duotone } from "@fortawesome/fontawesome-svg-core/import.macro";

export const Start = () => {
    
    const lang = useLanguage();

    // const os = useOs();
    // // const isMobile = os === 'ios' || os === 'android';
    
    const isMobile = useMediaQuery('(max-width: 768px)');


    // isMobile ? console.log("Mobile") : console.log("Desktop");
    
    const scan = () => {
        App.publish('qr-code-scan', {
            info: 'test'
        });
    }
    
    
    return <div className="fixed inset-0 isolate overflow-hidden bg-gray-800">
        <div className="flex justify-center items-center h-screen">  

            {/* <div>
                <h1 className="text-4xl font-bold text-white tracking-tight sm:text-6xl">
                    Exagard
                </h1>
            </div> */}

            <Card className={"w-full mx-4 sm:w-3/4 h-1/2 bg-gray-700 z-50 "} radius="lg" shadow="sm" >
                
                <Card.Section className="flex justify-center items-center pt-8">
                <h1 className="text-4xl font-bold text-white tracking-tight sm:text-6xl">
                    {lang.Text('Start')}
                </h1>
                </Card.Section>
                

                <Card.Section className="flex justify-center items-center pt-10">
                <p className="text-white text-sm">
                    {lang.Text('Scan the QR-Code on starting point')}
                </p>
                </Card.Section>
                <Card.Section className="flex justify-center items-center pt-10">
                    <FontAwesomeIcon icon={duotone("qrcode")} className="text-white text-7xl" />
                </Card.Section>

                <Card.Section className="flex justify-center items-center pt-12">
                    <Button variant="filled" color={Exagard_Colors._green} className="w-1/2" size="lg"
                        onClick={scan}>
                        {lang.Text('Scan')}
                    </Button>
                </Card.Section>
            </Card>

        </div>
      {/* Overlay  */}
         <div
        className="absolute transform-gpu blur-3xl left-48 top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
        aria-hidden="true"
      >
        <div
          className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20 z-50"
          style={{
            clipPath:
              'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
          }}
        />
      </div>


      <QRScanner dialog={!isMobile} onScanned={(data) => {
        console.log(data);

        // if (GlobalAppsettings.IsDevelopment) {    
        //     const url = '/round/31206389-5fe0-4617-9204-cd8c92c1765c' ;
        //     window.location.href = url;
        // }
        // else {
            const url = '/resource/' + data;
            window.location.href = url;
        // }

      }}/>
    </div>
}
