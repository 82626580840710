import { Line, Text } from '@react-three/drei'
import { useMousePosition } from '../hooks/useMousePos';
import { Point } from '../api/Sections';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ThreeEvent, useFrame, useLoader } from '@react-three/fiber';
import { useGesture } from '@use-gesture/react';

import * as THREE from 'three';
import { RayEvent } from '../api/Raycasting';
import { Exagard_Colors } from '../../../GlobalSettings';

import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';

import arrowSVG from './images/arrow.svg';
import { RouteLayer } from '../api/RouteLayer';
import { useForceUpdate } from '@mantine/hooks';

// Snap function to round to the nearest grid point
const snapToGrid = (value: number, gridSize: number) => Math.round(value / gridSize) * gridSize;

const intersectPlane = (event: THREE.Event, plane: THREE.Plane, 
    planeIntersectPoint: THREE.Vector3): Point => {
  let _event = event as unknown as RayEvent;
  _event.ray.intersectPlane(plane, planeIntersectPoint);
  return {x: planeIntersectPoint.x , y: planeIntersectPoint.z};
}

function AnimatedMarker({ curve, offset }: {curve: THREE.Curve<THREE.Vector3>, offset: number}) {
  const markerRef = useRef<THREE.Group>(null);
  const [t, setT] = useState(offset); // Start with different offset positions

  const svg = useLoader(SVGLoader, arrowSVG);

  // Convert SVG paths to line geometries
  const lines = useMemo(() => {
    const paths = svg.paths;
    const lineGeometries: THREE.BufferGeometry[] = [];

    paths.forEach((path) => {
      path.subPaths.forEach((subPath) => {
        const points = subPath.getPoints(); // Get points of the open path
        const geometry = new THREE.BufferGeometry().setFromPoints(points);
        lineGeometries.push(geometry);
      });
    });

    return lineGeometries;
  }, [svg]);

  //console.log ("svg: ", svg);
  //console.log("shapes: ", shapes);

  useFrame((state: {clock: THREE.Clock}, delta: number) => {
    
    if (!markerRef.current) return;
    
    setT((t) => (t + delta * 0.2) % 1);
    const point = curve.getPointAt(t);
    const tangent = curve.getTangentAt(t);

    //markerRef.current.position.set(point.x, point.y, point.z);
    //markerRef.current.lookAt(point.x + tangent.x, point.y + tangent.y, point.z + tangent.z);
    // markerRef.current.rotation.y = Math.PI / 1; // 90 degrees around the Z-axis
    // markerRef.current.rotation.x = Math.PI / 2; // 90 degrees around the Z-axis
    // Set the marker's position on the curve
    markerRef.current.position.set(point.x, point.y, point.z);

    // Manually calculate the orientation using the tangent
    const axis = new THREE.Vector3(1, 0, 0); // Y-axis, since it's on the XY-plane
    const angle = Math.atan2(tangent.y, tangent.x); // Angle of rotation on the XY-plane

    // Rotate the marker along the Z-axis based on the angle along the curve
    markerRef.current.quaternion.setFromAxisAngle(axis, angle);

    // Additional rotation adjustment if needed
    //markerRef.current.rotation.x += Math.PI / 2; // Rotate 90 degrees for proper facing

    // Blinking effect: Modify scale smoothly every second using sine function
    //const scale = 1 + 0.2 * Math.sin(state.clock.getElapsedTime() * Math.PI * 2 * 0.5); // Blink every second
    //markerRef.current.scale.set(scale, scale, scale); // Apply scale equally on all axes

     // Blinking effect: switch between two sizes every second
     const elapsedTime = state.clock.getElapsedTime();
     const isBig = Math.floor(elapsedTime / 0.2) % 2 === 0; // Toggle every second
     const scale = isBig ? 1.5 : 1; // 1.5 is bigger, 1 is normal size
     markerRef.current.scale.set(scale, scale, scale); 
  });

  //console.log("shapes: ", shapes);

  return (<>
    <group 
    // scale={0.1} 
    position={[0, 0.1, 0]}
    ref={markerRef} 
    // rotation={[0, 0, Math.PI / 2]}
    >
       {/* {lines.map((geometry, index) => (
        <line key={index}>
          <bufferGeometry attach="geometry" {...geometry} />
          <lineBasicMaterial color="red" />
        </line>
      ))} */}
      
      <mesh >
        <sphereGeometry args={[0.2, 16, 16]} />
        <meshStandardMaterial color={'orange'} 
        />
      </mesh>
    </group>
    </>
  );
}

const DashedRoute = ({ points, width = 10 }: {points: Point[], width?: number}) => {
  
  const lineRef = useRef(null);

  if (points === undefined || points === null || points.length < 2) return null;
  // Create a CatmullRomCurve3 to interpolate between points
  const curve = new THREE.CatmullRomCurve3(points.map((point) => new THREE.Vector3(point.x, 0.02, point.y)));

  // Get points along the curve to use for the Line component
  const curvePoints = curve.getPoints(50); // 50 points along the curve for smoothness

  return (<>
    <Line
      ref={lineRef}
      points={curvePoints.map(p => [p.x, p.y, p.z])} // Convert curve points to arrays
      color={Exagard_Colors._green}
      lineWidth={width} // Adjust the width of the line
      dashed={true}
      dashScale={8}
    />
   
    {/* <AnimatedMarker curve={curve} offset={0} /> */}
    
    </>
  );
}

export const useMarkerLayer = (props: { 
    setIsDragging: (value: boolean) => void, routeLayers: RouteLayer[], 
    saveRouteLayers: (routeLayer: RouteLayer[]) => void}
  ) => {

  const forceUpdate = useForceUpdate();

  const [layers, setLayers] = useState<RouteLayer[]>(props.routeLayers);

  const [activeLayer, setActive] = useState<RouteLayer | null>(null);
  
  const plane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);
  const planeIntersectPoint = new THREE.Vector3();

  const addPoint = (event: THREE.Event) => {
    let point = intersectPlane(event, plane, planeIntersectPoint);
    let newLayer = activeLayer;
    if (newLayer && newLayer.points === undefined) newLayer.points = [];
    if (newLayer) {
      newLayer.points.push({x: snapToGrid(point.x, 0.5), y: snapToGrid(point.y, 0.5)});
      setActive(newLayer);
      saveActiveLayer();
      forceUpdate();
    }
  }

  const updateMarkerLayers = (newLayers: RouteLayer[]) => {
    setLayers([...newLayers]);
  }

  const saveActiveLayer = () => {
    if (activeLayer !== null) {
      props.saveRouteLayers([...layers, activeLayer]);
    }
  }

  const setActiveMarkerLayer = (keyPointRef?: string) => {
    let find = layers.find(l => l.keyPointRef === keyPointRef);
    if (find) {
       setActive(find); 
    }
    else {
      console.log("layer not found: ", keyPointRef, layers);
      setActive(null);
    }
    forceUpdate();
  }

  useEffect(() => {
    if (layers.length > 0) {
      setActiveMarkerLayer(layers[0].keyPointRef);
    }
  }, [layers]);
  
  const renderMarkerLayer = (editmode: boolean, addPointMode: boolean) => {
  
  if (activeLayer === null) return null;

  //console.log("renderMarkerLayer: ", activeLayer);

  return (<>
  <group >
      {editmode ? <>
      {activeLayer.points.map((point, index) => (
          <Marker key={index} position={point} 
            updatePosition={(position: Point) => {
              activeLayer.points[index] = position;
              saveActiveLayer();
            }}
            editMode={editmode} 
            setIsDragging={props.setIsDragging} />
      ))}
      </> : null}

      {editmode ? <>
      {/* Invisible clickable plane */}
      <mesh
        onClick={(event: THREE.Event) => {
          console.log("addPointMode: ",  addPointMode);
          if (addPointMode) {
            addPoint(event);
          }
        }}
       
        rotation={[-Math.PI / 2, 0, 0]} // Rotate plane to be horizontal
        position={[0, 0, 0]} // Position it on the ground
        onPointerDown={(e) => e.stopPropagation()} // Stop propagation to avoid issues
      >
        <planeGeometry args={[50, 50]} /> {/* Adjust size of the plane */}
        <meshBasicMaterial transparent={true} opacity={0} /> {/* Invisible material */}
      </mesh></> : null}
    </group>

    {/* <Route points={points} height={1} thickness={5} color={Exagard_Colors._dark_green} /> */}

    <DashedRoute points={activeLayer?.points ?? []} />
    </>
    )
  }

  return {
    renderMarkerLayer,
    setActiveMarkerLayer,
    updateMarkerLayers,
    getMarkerLayers: () => layers
  }
}


export const Marker = (props: {position: Point, editMode: boolean,
    updatePosition: (position: Point) => void,
    setIsDragging: (value: boolean) => void, ref?: React.RefObject<THREE.Mesh>}) => {
  
   const [pos, setPos] = useState<[number, number, number]>([props.position.x, 0.1, props.position.y]);

   let planeIntersectPoint = new THREE.Vector3();
   let plane = new THREE.Plane(new THREE.Vector3(0, 1, 0), 0);

  const bindPos = useGesture({
      onDrag: ({ active, event }) => {
          if (active) {                
              let _event = event as unknown as RayEvent;
              _event.ray.intersectPlane(plane, planeIntersectPoint);
              setPos([snapToGrid(planeIntersectPoint.x, 0.5), 0.1, snapToGrid(planeIntersectPoint.z, 0.5)]);
          }
      }
  })

   const movePosDown = (event: ThreeEvent<PointerEvent>) => {
      if (props.editMode) {
          props.setIsDragging(true);
          let c = bindPos();
          if (c && c.onPointerDown) c.onPointerDown(event as unknown as React.PointerEvent)
      }
  }

  const movePosUp = (event: ThreeEvent<PointerEvent>    ) => {
    if (props.editMode) {
        props.setIsDragging(false);
    
        if (props.updatePosition) {
          props.updatePosition({x: snapToGrid(pos[0], 0.5), y: snapToGrid(pos[2], 0.5)});
        }
        let c = bindPos();
        if (c && c.onPointerUp) c.onPointerUp(event as unknown as React.PointerEvent);
    }
}

const movePos = (event: ThreeEvent<PointerEvent>) => {
    if (props.editMode) {
        let c = bindPos();
        if (c && c.onPointerMove) c.onPointerMove(event as unknown as React.PointerEvent)
    }
}
  
  return (
    <mesh position={pos} scale={0.2} {...bindPos} ref={props.ref}
    
    // onPointerEnter={(event) => {
    //   console.log("pointer enter: ", event);
    // }}
    // onPointerLeave={(event) => {
    //   console.log("pointer leave: ", event);
    // }}

    onPointerDown={(event) => {
      movePosDown(event);
    }}

    onPointerUp={(event) => {
      movePosUp(event);
    }}

    onPointerMove={(event) => {
      movePos(event);
    }}
    >
      <sphereGeometry args={[0.8, 16, 16]} />
      <meshStandardMaterial color={'orange'} />

      {/* <Text
        position={props.position}
        fontSize={0.2}
        scale={2.1}
        color="black"
        anchorX="center"
        anchorY="middle"
      >
        {props.text}
      </Text> */}
    </mesh>
  );
}